import { template as template_f96bb1d196e14ae98bc91c24d84386a3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f96bb1d196e14ae98bc91c24d84386a3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
