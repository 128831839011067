import { template as template_9cff069f1b744285aa84a97ceb7d43de } from "@ember/template-compiler";
const FKText = template_9cff069f1b744285aa84a97ceb7d43de(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
