import { template as template_511205e40e4145ab846d6f6b201bc3db } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_511205e40e4145ab846d6f6b201bc3db(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
